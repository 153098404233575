// Inertia app
import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "ziggy-js";

// Libraries
import moment from "moment";
import * as Sentry from "@sentry/vue";
import _ from "lodash";
window._ = _;

// Utilities
import formatter from "./util/formatter.js";
import util from "./util/util.js";
import posthogPlugin from "./util/posthog.js";
import "./util/axios.js";
import "./util/chart.js";

// Components
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AppLayout from "@/Shared/Layouts/AppLayout.vue";
import PrimeVue from "primevue/config";
import Aura from "@/presets/aura";
import Tooltip from "primevue/tooltip";

// Inertia setup
const inertiaApp = createInertiaApp({
  title: title => `${title} | Icehouse Ventures`,
  progress: {
    color: "#52B789",
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
  setup({ el, App, props, plugin }) {
    const VueApp = createApp({ render: () => h(App, props) });

    VueApp.use(plugin)
      .use(PrimeVue, {
        unstyled: true,
        pt: Aura
      })
      .use(ZiggyVue)
      .use(posthogPlugin)
      .directive("tooltip", Tooltip)
      .component("Link", Link)
      .component("Head", Head)
      .component("AppLayout", AppLayout)
      .component("VueGoogleAutocomplete", VueGoogleAutocomplete);

    VueApp.config.globalProperties.$filters = formatter;
    VueApp.config.globalProperties.$moment = moment;
    VueApp.config.globalProperties.$util = util;
    VueApp.config.compilerOptions.whitespace = "preserve";

    Sentry.init({
      app: VueApp,
      dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN || "",
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
      ],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      tracePropagationTargets: [
        "icehouseventures.co.nz"
      ],
      ignoreErrors: [
        "Failed to fetch dynamically imported module",
        "Importing a module script failed",
      ],
    });

    VueApp.mount(el);

    return VueApp;
  },
});

window.addEventListener("vite:preloadError", (event) => {
  window.location.reload();
});
