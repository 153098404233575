<template>
  <nav
    v-if="$page.props.user"
    class="flex-1 mt-10 md:divide-y-0 divide-y divide-dashed divide-zinc-800"
  >
    <Link
      :href="route('investors.home')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.home') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-house mr-3" /> Dashboard
    </Link>

    <Link
      :href="route('investors.fund.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.fund.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-layer-group mr-3" /> Fund Investments
    </Link>

    <Link
      :href="route('investors.direct.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.direct.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-bullseye mr-3" /> Direct Investments
    </Link>

    <Link
      v-if="$page.props.user.has_custodial"
      :href="route('investors.custodial.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.custodial.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-chart-pie-simple mr-3" /> Custodial
    </Link>

    <Link
      :href="route('investors.portfolio.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': (route().current('investors.portfolio.*') || route().current('investors.company.*')) && !route().current('investors.company.benchmarks.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-globe mr-3" /> Portfolio
    </Link>

    <Link
      :href="route('investors.transaction.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.transaction.*') && !route().current('investors.company.metrics.*') && !route().current('investors.company.benchmarks.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-regular fa-arrow-right-arrow-left mr-3" /> Transactions
    </Link>

    <Link
      :href="route('investors.offer.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.offer.*') || route().current('investors.indication*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-dollar-sign mr-4 ml-1" /> Invest
    </Link>

    <Link
      :href="route('investors.statement.generator')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.statement.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-file-invoice mr-3" /> Statements
    </Link>
    <Link
      :href="route('investors.benchmarks.charts')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.benchmarks.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-chart-mixed mr-3" /> Analysis
    </Link>

    <Link
      :href="route('investors.news.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.news.*') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-newspaper mr-3" /> News
    </Link>

    <Link
      :href="route('investors.settings')"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-gear mr-3" /> Settings
    </Link>

    <Link
      :href="route('logout')"
      method="post"
      as="button"
      class="group flex lg:hidden items-center mx-4 w-full text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-arrow-right-to-bracket mr-3" /> Logout
    </Link>

    <div v-if="$page.props.user.is_esop_participant">
      <hr class="w-52 h-[0.5px] mx-auto my-1 border-0 bg-white bg-opacity-[0.2]">

      <Link
        v-if="$page.props.user.is_esop_participant"
        :href="route('esop.home')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('esop.*') }"
        class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-lg leading-5 text-zinc-400 focus:outline-none hover:border-white "
      >
        Equity
      </Link>
    </div>

    <!-- User Mode Context Buttons-->

    <div v-if="$page.props.user.has_raise_company">
      <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

      <Link
        :href="route('raise.company')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('raise.*') }"
        class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Company
      </Link>
    </div>

    <div v-if="$page.props.user.has_fund_operator">
      <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

      <Link
        :href="route('operator.overview')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3': route().current('operator.*') }"
        class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Fund
      </Link>
    </div>

    <div v-if="$page.props.user && $page.props.user.is_admin">
      <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

      <a
        href="/admin"
        class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
      >
        Staff Portal
      </a>
    </div>
    <div v-if="$page.props.user && $page.props.user.is_impersonating && $util.isMobile()">
      <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

      <a
        :href="route('impersonate.stop', { destination: 'admin.home' })"
        class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
      >
        Stop Impersonating
      </a>
    </div>
  </nav>
  <nav v-else>
    <Link
      :href="route('login')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('login') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-regular fa-right-to-bracket mr-3" /> Login
    </Link>
  </nav>
</template>
