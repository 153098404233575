<template>
  <div class="relative w-full">
    <div
      v-show="showArrows"
      class="z-10 absolute cursor-pointer left-0 top-2 right-arrow bg-zinc-100 rounded-full w-[30px] h-[30px] text-center pt-1 border"
      @click="scrollLeft"
    >
      <i class="fa-solid fa-angle-left" />
    </div>

    <div
      ref="navContainer"
      class="flex items-center w-full overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']"
      :class="{['scroll-smooth'] : scrollSmooth}"
    >
      <div
        class="flex z-0 space-x-2 ml-2 mr-10 md:mr-0"
        :class="{
          ['first:ml-10 last:mr-10']: showArrows
        }"
      >
        <template
          v-for="(link, index) in filteredLinks"
          :key="index"
        >
          <Link
            v-if="(link.url || link.route) && link.hide !== true"
            :href="!disableLinks ? (link.url ? link.url : route(link.route)) : null"
            class="nav-item text-nowrap z-0 min-w-24 text-center text-sm text-zinc-400 transition ease-in-out duration-150  bg-zinc-800  hover:bg-white hover:text-black rounded-3xl py-3 px-5"
            :class="{
              ['active-menu cursor-default font-medium !bg-white !text-black rounded-3xl']: (link.route && route().current(link.route)) || link.highlightRoute === route().current(),
            }"
          >
            {{ link.name }}
          </Link>
        </template>
      </div>
    </div>

    <div
      v-show="showArrows"
      class="z-10 absolute cursor-pointer right-0 top-2  right-arrow bg-zinc-100 rounded-full w-[30px] h-[30px] text-center pt-1 border"
      @click="scrollRight"
    >
      <i class="fa-solid fa-angle-right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionColor: String,
    links: Array,
    disableLinks: Boolean,
  },
  data() {
    return {
      userMenuOpen: false,
      mobileNavOpen: false,
      scrollPosition: 0,
      containerWidth: 0,
      contentWidth: 0,
      scrollSmooth: false,
    };
  },
  computed: {
    showArrows() {
      return this.containerWidth < this.contentWidth;
    },
    filteredLinks() {
      return this.links.filter(link => link.step ? link.step === link.show : true);
    },
  },
  mounted() {
    this.scrollToActiveMenu();
    this.$refs.navContainer.addEventListener("scroll", this.updateScrollInfo);

    this.scrollSmooth = true;
  },
  beforeUnmount() {
    this.$refs.navContainer.removeEventListener("scroll", this.updateScrollInfo);
  },
  methods: {
    scrollLeft() {
      this.$refs.navContainer.scrollLeft -= 250;
    },
    scrollRight() {
      this.$refs.navContainer.scrollLeft += 250;
    },
    updateScrollInfo() {
      this.scrollPosition = this.$refs.navContainer.scrollLeft;
      this.containerWidth = this.$refs.navContainer.clientWidth;
      this.contentWidth = this.$refs.navContainer.scrollWidth;
    },
    scrollToActiveMenu() {
      this.updateScrollInfo();

      const activeMenu = document.querySelector(".active-menu");
      const rect = activeMenu ? activeMenu.getBoundingClientRect() : "";
      
      this.$refs.navContainer.scrollLeft = rect.left - 100;
    }
  },
};
</script>

