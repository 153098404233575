<template>
  <div class="w-full bg-white mb-1">
    <nav class="w-full py-3 bg-white flex items-center justify-between overflow-x-auto md:px-28 overflow-y-hidden max-w-7xl mx-auto">
      <Link
        :href="route('esop.management.overview')"
        :class="{ [`font-bold text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.overview'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.overview')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Overview
      </Link>

      <Link
        :href="route('esop.management.plan.index')"
        :class="{ [`font-bold text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.plan.*'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.plan.*')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Plans
      </Link>

      <Link
        :href="route('esop.management.grant.index')"
        :class="{ [`font-bold text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.grant.*'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.grant.*')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Grants
      </Link>

      <Link
        :href="route('esop.management.exercise.index')"
        :class="{ [`font-bold text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.exercise.*'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.exercise.*')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Exercises
      </Link>

      <Link
        :href="route('esop.management.employee.index')"
        :class="{ [`font-bold text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.employee.*'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.employee.*')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Employees
      </Link>

      <Link
        :href="route('esop.management.document.index')"
        :class="{ [`font-bold underline underline-offset-[6px] text-${sectionColor} hover:bg-${sectionColor}`] : route().current('esop.management.document.*'),
                  [`text-slate-500 hover:bg-${sectionColor} hover:text-${sectionColor}`] : !route().current('esop.management.document.*')}"
        class="m-2 px-3 rounded lg:px-4 py-3 text-lg border-b-4 border-transparent hover:font-bold hover:bg-opacity-[0.05] transition ease-in-out duration-150"
      >
        Documents
      </Link>
    </nav>
  </div>
</template>

<script>

export default {
  props: {
    sectionColor: String,
  },
  data() {
    return {
      userMenuOpen: false,
      mobileNavOpen: false,
    };
  },
};
</script>
