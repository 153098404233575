export default {
  root: {
    class: [
      // Alignment
      "flex items-center",
      "gap-2",
      "[&_[data-pc-name=pcinput]]:w-10"
    ]
  }
};
