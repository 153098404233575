<template>
  <div
    v-if="$page.props.user"
    class="w-full lg:hidden"
    :class="{ ['fixed z-40'] : openMenu}"
  >
    <div
      class="w-full px-4 py-3 text-white flex justify-start items-center"
      :class="bgClass"
    >
      <div class="pr-[25px]">
        <Link :href="route('esop.home')">
          <img
            src="/images/logo-small.png"
            alt="Icehouse Ventures Logo"
            width="28"
          >
        </Link>
      </div>

      <div class="w-full flex justify-between items-center">
        <p>{{ $page.props.user.name }}</p>

        <button
          class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-white focus:bg-opacity-[0.1] transition duration-150 ease-in-out"
          :class="{'bg-gray-100 bg-opacity-[0.1]': openMenu }"
          @click="openMenu = !openMenu"
        >
          <!-- Menu open: "hidden", Menu closed: "block" -->
          <svg
            v-show="!openMenu"
            class="block h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <!-- Menu open: "block", Menu closed: "hidden" -->
          <svg
            v-show="openMenu"
            class="block h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>

    <div
      v-show="openMenu"
      class="text-black fixed w-full min-h-screen bg-white left-0"
    >
      <div class="pt-2 pl-3 pb-3 pr-3">
        <p class="block py-2 text-xl font-bold text-[#2E4D7A]">
          Manage Company
        </p>

        <Link
          :href="route('raise.company')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('investors.raise.*') }"
        >
          Raise Capital
        </Link>

        <Link
          :href="route('company.metrics.index')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('investors.company.metrics.*') }"
        >
          Metrics
        </Link>

        <Link
          :href="route('company.benchmarks.charts')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('investors.company.benchmarks.*') }"
        >
          Benchmarks
        </Link>

        <Link
          :href="route('esop.management.overview')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('esop.management.*') }"
        >
          ESOP Management
        </Link>

        <Link
          :href="route('investors.settings')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('investors.settings') }"
        >
          Settings
        </Link>

        <Link
          :href="route('investors.home')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-blue-700': route().current('investors.home') }"
        >
          Investments
        </Link>

        <Link
          v-if="$page.props.user.is_esop_participant"
          :href="route('esop.home')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-orange-500': route().current('esop.home') }"
        >
          Equity
        </Link>

        <Link
          v-if="$page.props.user.has_fund_operator"
          :href="route('operator.overview')"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
          :class="{ 'text-orange-500': route().current('operator.overview') }"
        >
          Manage Fund
        </Link>

        <a
          v-if="$page.props.user && $page.props.user.is_impersonating"
          href="/impersonate/leave"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
        >Stop Impersonating</a>
        <a
          v-if="$page.props.user && $page.props.user.is_admin"
          href="/admin"
          class="block py-2 text-base font-medium border-t border-dotted border-gray-500"
        >Staff Portal</a>

        <Link
          :href="route('logout')"
          method="post"
          as="button"
          class="w-full text-left block py-2 text-base font-medium border-t  border-b border-dotted border-gray-500"
        >
          Logout
        </Link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    sectionColor: String
  },
  data () {
    return {
      openMenu: false,
    };
  },
  computed: {
    bgClass () {
      return "bg-" + this.sectionColor;
    }
  },


};
</script>
