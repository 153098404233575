import currency from "vue2-filters/src/other/currency";
import number from "vue2-filters/src/other/number";
import percent from "vue2-filters/src/other/percent";
import truncate from "vue2-filters/src/string/truncate";

export default {
  currency,
  number,
  percent,
  truncate
};
