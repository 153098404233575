<template>
  <MultiSelect
    v-model="form.currentEntities"
    :options="$page.props.user.raw_entities"
    option-label="entity_name"
    placeholder="Entities Selection"
    data-key="id"
    input-id="id"
    variant="filled"
    option-value="id"
    :show-toggle-all="false"
    class="w-full md:w-56 !bg-black !border-zinc-500 !text-zinc-500"
    selected-items-label="Entities Selection"
    pt:list:class="p-1 list-none m-0 rounded-b font-normal"
    pt:label:class="text-zinc-500 py-3 px-2"
    pt:header:class="flex items-center justify-between pt-2 px-4 pb-0 gap-2 rounded-t"
    pt:overlay:class="border border-zinc-500 bg-zinc-900 rounded-md text-zinc-300"
    :max-selected-labels="0"
    :loading="sending"
  />
</template>
<script>
import MultiSelect from "primevue/multiselect";
import { router } from "@inertiajs/vue3";

export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      sending: false,
      form: {
        currentEntities: this.$page.props.user ? this.$page.props.user.current_entities : [],
      },
    };
  },

  watch: {
    "form.currentEntities" (currentEntities, previousEntities) {
      if (currentEntities.length === 0) {
        this.form.currentEntities = previousEntities;
      } else {
        this.submit();
      }
    }
  },

  methods: {
    submit () {
      this.sending = true;

      router.post(this.route("investors.settings.store"), this.form, {
        preserveScroll: true,
        onError: (errors) => {
          this.sending = false;
        },
        onSuccess: () => {
          this.sending = false;
        },
      });
    }
  },

};
</script>