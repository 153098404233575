<template>
  <div v-if="$page.props.user">
    <nav class="bg-white border-t-2 border-blue-200 pt-3 pb-2 lg:hidden fixed bottom-0 left-0 w-full z-50">
      <div class="flex justify-between items-center">
        <Link
          :href="route('raise.company')"
          class="px-3 text-sm"
          :class="{ 'font-bold text-green-500': route().current('investors.raise.*') }"
        >
          <img
            src="/images/nav/direct.png"
            class="mx-auto"
            width="20"
          >
          Raise Capital
        </Link>
        <Link
          :href="route('company.benchmarks.charts')"
          class="px-3 text-sm"
          :class="{ 'font-bold text-teal-700': route().current('investors.company.*') }"
        >
          <img
            src="/images/nav/funds.png"
            class="mx-auto"
            width="20"
          >
          Metrics
        </Link>
        <Link
          :href="route('esop.management.overview')"
          class="px-3 text-sm"
          :class="{ 'font-bold text-blue-700': route().current('esop.management.*') }"
        >
          <img
            src="/images/nav/statements.png"
            class="mx-auto"
            width="20"
          >
          ESOP Management
        </Link>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data: () => ({
    openMenu: false,
  }),


};
</script>
