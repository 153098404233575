<template>
  <div class="mt-10">
    <Message
      closable
      :severity="severity"
    >
      <div class="flex space-x-1 items-center">
        <span v-if="type === 'is-error'">
          <i class="fa-solid fa-circle-exclamation mr-2" />
        </span>
        <span v-else-if="type === 'is-success'">
          <i class="fa-sharp fa-solid fa-circle-check mr-2" />
        </span>
        <span v-else-if="type === 'is-indication'">
          Pending:
        </span>

        <div v-html="content" />

        <p
          v-if="type === 'is-indication'"
          class="text-center text-lg leading-5 font-medium"
        >
          <Link :href="route('investors.indication.index')">
            Resume Investment
          </Link>
        </p>
      </div>
    </Message>
  </div>
</template>

<script>

import Message from "primevue/message";


export default {
  components: {
    Message
  },
  props: {
    type: String,
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      severity_map: {
        "is-error": "error",
        "is-success": "success",
        "is-indication": "secondary",
        "is-info": "info",
        "is-warning": "warn"
      }
    };
  },
  computed: {
    severity() {
      return this.severity_map[this.type];
    }
  },
};
</script>
